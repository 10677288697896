export default {
    default: 'OjqkBWp9VpYP8utpWdzBZ+eZIJhcfOW5U9g4/w==',
    //
    '100sport': 'Uq5GO7mRz+oxp7pVh3bZEvDEBzEYRTG4h3sr1Q==',
    ab: 'qK+optxkJTV2KblAIiLdoWze6AAGxX5Qxeb57A==',
    adressa: 'BQs7m1EiK0wlK8xAZQgJwU1a4OskbKgoYRSEfA==',
    adressabrandstudio: 'fuz93B3kwPfLDZv+opQh6xasO9nRkraYSbQP0A==',
    altaposten: 'Y0grkUQ6BaCat409fP7mPqr1SbDS+LntWrQIpg==',
    ap: 'ElscszbjUK+A0En7My3UPpJ/4MlOUOn+fiU2/w==',
    bt: 'wgmdmAfgbneZjUBt8ZxqiKTxVCpriTwcC06hzQ==',
    brandstudio: 'uXOJWRxZaxOSRhIisAGWsEbzn3PIhbTlCn99IQ==',
    folkebladet: 'kGoJc75y4Uq1r2kw2n883oYzGk+SrEJ2GEDQUQ==',
    fvn: '88vcnFV8g4crXq1P8jrGz8D7XqMwawGvwFmhJg==',
    harstad: 'C8hK/HIbIL3k9h4dN4xY3lHAPdjLyx7B4WGM/w==',
    itromso: 'QAPvDWM3mHi3lYOZCLqOwHZMstixgFefhc/d1Q==',
    podcast: 'X2K7i+dwWXsIR0BKyX+3C+8s4y9VOWcYgtjeJA==',
    rbnett: 'y7Z4M5Ah6dOyDH7/ZrCQjHZPb3E3xnctwkixbw==',
    riks: 'a5kTQmkeGHV+P2wOaTQvAuXEdivj2lbXoAxTbw==',
    ridenews: '6s5IkHj1LiODLxDOaYr1ze6F7F1Cun92UFuOpA==',
    sa: 'dWpue+L8nJKbDFqh9Ps3o2mxqr92MY2G9tQohg==',
    schibsted: 'Cn+pnRPeR7dlwUgy+QwoSwyu0NXoGUtxD/LVqA==',
    smp: 'eZ34FUhLc6cW3MypHSgtbYwRgbDxKLV/iaABmQ==',
    svd: 'Olwljnb5W0VdUf38yWyv3kjowR9JamQYw4RndA==',
    tronderavisa: '7azVhGsMlCm9EuRPr4EJ8RY6cyVbmAgeS1MkKw==',
    vgtv: 'edF0bFyKcM68omCq24JsMWw/CO/f6cAgS91oiQ==',
    vgtvs: 'D/kRElM9UDSgZxbEqPBESxIKamNBql5PeIabpg==',
    tvnu: 'D3dYmVFYpKAvLGpVd8pGy5WaK2ENvBtw/Ckcpg==',
    tvh: 'ruSROpB1eB80YOC8rhSGsPtRYOuyY3dVgvuENA==',
    steinkjer: '6R+PbNB3P4oBInKbvaTZ43gQ2ytS033VqoeXCw==',
    avisast: 'QcZcFVTVlh+UzfQwef0geS5SG8kfbuqpSbpBeA==',
    bladet: '1kmygQh66fY6JQRpflRV7+YGryfifrMtVFP3Yg==',
    banett: 's4vq0YvNHbJwTC4xIWv8gKbwgBhuk8lVzyOT8w==',
    innherred: '0yUhyfky20/BmQ0m34/m5xjUj7jZ+hbs6NGbaQ==',
    fosnafolket: 'URfW4iMX1EXaAMNIfXQMH4vFM45poWC3w/exaA==',
    opp: 'ZPPMwo4Hi2zY83ujh8eKtEZyLbnIDiiZ6FmM/A==',
    tronderbladet: 'QvkL/hGUNa8LhqWMWf0BBO5KIxtrY6tM/V9YzA==',
    stjordalsnytt: 'Vrr3fuhtCbYFD65BiG71D7LG85GJGbhJtsG0gg==',
    mn24: '/C0MEydhFzj2pHNF2s/sCJxSqmT10BN44TwB7g==',
    hitrafroya: 'dKm1wQ/c9wb8RUV17DcB0BcnWu6LwWOVwIwdOg==',
    framtidinord: 'kK2ipLqdfYIbhGdvjOthFN4jGIvUi8N40xzn4Q==',
    vaganavisa: 'EmRtKKUtomHJbI3SoSaGcUFmTH09+P1RWQJhFA==',
    vol: '5uns40a+PtZHBW63Js08tgiKxoLP9lvKzeSQhg==',
    nyttiuka: 'y3FqylNKM83/cm/EWqK8VMXaAlhZ8SugNLbLUA==',
    vikebladet: 'wjNCSWk5Pa+NP3himjg5l3SP6zJiwtfcio6Q4Q==',
    fjt: 'fwjuzLToVU9Ge7szNfMQjku/mrHv+rHKmnDWIw==',
    vestlandsnytt: 'eeXBMlgZz3eV1P40G2n8w5xNj6I26P6uPqGXrw==',
    fjuken: 'yJRnk09dbww1J8o1ZcttIz6JEWIbKQEVc2dBWA==',
    vigga: 'CVPhE9cnKo/qCEYsnKY4srRPlUX5nIkpgaYmmA==',
    dolen: '0pwgbRB6x1e0lopRdq63Z4dr9dWmCJv+y6lDjA==',
    fjordabladet: 'GSaj2C8vTpEIBol7cv22nushv81P41JejaYAYQ==',
    morenytt: 'y/F1oPTks00zkuYbKzS/J5nv1kj3F7bzP9mYyA==',
    fjordingen: 'qKK3mb0M6WSP0x2m6tivcPISciI3XcrXbQ6Ahg==',
    driva: 'UrdUWdGCy1fRuOhiW73+OquZdf4X4pEQt+wWAg==',
    andalsnes: '1R1UB8uyMZTD2ktpNUGmWwQ1rRJqdddbTr35hA==',
    nyss: 'KtxtrKsDnYN56mQzwJR0yQrt3v6txf5deJbU4Q==',
    agderposten: '1ddblFxmHMTjY92s/n4MW4UOarEg8Y0YdxnTLA==',
    lindesnes: 'BCo2euu9kUoY98D325ELxqI6xM2YkdZyg7/muQ==',
    farsund: 'qCM539Tebp04kUVjiaLyL7pkGCCDJamyVyu/zA==',
    varden: 'rKy6/CASIAoRtm+px2g9c78jIoqfW8zNaOlHMg==',
    lp: 'kKDr8twymQP1JZL6JSr+1+dnov/tgzOdcrg1TA==',
    venneslatidende: 'ssbp/U5E/VpJfjG1iKiRn4s1aEcmSJJVUvArHg==',
    gat: 'lsDEJw9LYcQHNSW7z1ANuZlP1az9SYVeVLz9nQ==',
    bomlonytt: 'JWrUR1BR1BDEvlk1D4zTVACUVUpSRmaHNPc3Ug==',
    sunnhordland: 'YNBOeqC8eCInTQKL6AAj3bUxvOBovPqzUIoGcQ==',
    klaebuposten: 'C4qzXiBP17SXr/vE8xhg24JOWtyoGDeDp5r6PQ==',
    gauldalsposten: 'eckuy2Y+Q1Ix2uLPX/mVUMHjfZGihqYYFbr9lg==',
    froya: '0hMWIeKp57C5kpW/SZZ1wt4UkFJHwmWYaCd25A==',
    bnytt: 'DZ3T3Pf0i7D5z/HvohVc0O7Nd+uA/NIaNWaWFA==',
    vnytt: 'LIf2gdiGI3pQ3xNFRA4VyY0Rw1m8Zt8INsG59w==',
    stri: 'gsS6gkjzxZM0HbwrApKOQptgKWAhLWjQiGKBqQ==',
    avavis: 'VKgcw+6bkoouV5F8wq3m3INf0/vnoAYm9A+iKw==',
    bareasane: 'EWqdeFg2tvV34V0/8Y8xPYs7nSJqhBca7YE9nA==',
    e24: 'EKxf/lgWjKyDmxngUI9GCHYadRPzPqJZ61eGsw==',
    op: 'D+Ha6SLZGzk9rTYF73CZVauhRaxcOWQJ1DcA7A==',
    r24: 'MCwzsnqjZ7mxAZIhFUaqLc9Jb6DJz16u19cJgw==',
    stord: '/XmpYhCtUCXEGxdg7Sx8uDV4H9B/S7pcoHFshw==',
    marsteinen: 'mgxyPEPhFgqBsXWID7XMFehHRtcmSV0NYBgtkQ==',
    aptts: 'iHIdsWq1mtMGYlPjI4bdBAv/Mi67voL7GPFxzQ==',
    vgtvexternal: '0CwPuvNeSfovbd5Z9E2BxESNFd0uCQuKGua/EQ==',
    wellobe: 'AB982A2pFmif19jnLW8g+9HAmRXj8WB9iLPcFQ==',
    godare: 'rW8bwL2zUArb4f17a9VzQXpPJvrKnuaB/ot6Xw==',
    nearadio: 'I3cMBc/Zc14sn4akbaJzItzyxSp9q97kKCBQ9w==',
    fitjarposten: 'Hqz/cmP6WSIqk5F322XILEoOh45aPi0IEyhvTw==',
    kulingen: 'imULXGlJMRjRsjeiEqCNTL3rBxQoWgQcCCCghQ==',
    kystogfjord: 'cI6D2aLVAUQpzIvhIXiBnMJHXlR0G2oW5pkHSw==',
    porten: 'FxTcRhFGJv+55ria65b8nOY/RzvuqG2nMjtrAw==',
    polaris: 'wUOdkU2sf3mAjVpDg/Dd1Ub16CPBWnW04+4Tlg==',
    bttts: '/qd0rFS3azoUlwYI9CvPXLPnEI+WDeZ00GeS1A==',
};
