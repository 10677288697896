import config from 'player/config';
import Player from 'player/player';
import image from 'utils/image';
import utils from 'utils/utils';

import locale from 'player/model/locale';
import Domain from 'player/model/domain';

import { youboraSdk } from 'player/playback/youbora';
import SvpPlugins from './player/plugins';

const SVP = {};
SVP.Player = Player;
SVP.Player.Plugins = SvpPlugins;
SVP.Player.version = config.version;

// by default antiadblock is turned off
Object.defineProperty(SVP.Player, 'defaults', {
    get() {
        return this.__defaults || {};
    },
    set(value) {
        // if params are not object then
        // override and force to object
        if (value !== Object(value)) {
            // eslint-disable-next-line no-param-reassign
            value = {};
        }

        Object.defineProperty(SVP.Player, '__defaults', {
            value: value,
            enumerable: false,
            configurable: false,
            writable: false,
        });
    },
});

/**
 * Load youbora plugin unless it is turn off in SVP.default
 * @type {boolean}
 */
const loadYoubora = (options) => {
    // youbora plugin can be disabled completely
    try {
        const forceLoad = localStorage.svpYoubora === 'true';
        if (!utils.device.isBot() && (forceLoad || options !== false)) {
            youboraSdk.load(options, forceLoad);
        }
    } catch (e) {
        // could not load youbora plugin
    }
};

const setLocale = (language = 'no') => {
    locale.setTranslations(config.translations(language));
};

const preInitialize = () => {
    image.initialize(
        utils.extend(config.images, {
            getPixelRatio: utils.device.getPixelRatio,
        }),
    );

    Domain.initialize(window.location.hostname);
};

const postInitialize = () => {
    // do not override locale set from config
    const wasLocaleSet = Object.keys(locale.getTranslations()).length !== 0;
    if (!wasLocaleSet) {
        // set locale for all players
        setLocale(SVP.Player.defaults.locale);
    }

    loadYoubora(SVP.Player.defaults.youbora);
};

export default SVP;
export { preInitialize, postInitialize };
